import {isBeforeNow, now} from "@js/modules/date";


/**
 * @typedef {object} LocalStorageHelper
 * @property {(string, any, {expireAt?: (Date|null)}): LocalStorageHelper} set
 */

const isValidPayload = payload => {
	return payload && typeof payload === "object"
		&& [
			"value",
			"expireAt",
		].every(k => k in payload);
};

/**
 *
 * Local storage helper
 * @type {{set(string, *, {expireAt?: (Date|null)}): LocalStorageHelper, get(string, *=): (*|null), has(string): boolean}}
 */
export const ls = {
	__: {
		getFromLocalStorage(key){
			const raw = localStorage.getItem(key);
			return raw !== null ? JSON.parse(raw) : null;
		},
		removeIfExpired(key) {
			const payload = this.getFromLocalStorage(key);
			if (!isValidPayload(payload))
				return;


			const { expireAt } = payload;

			if (expireAt !== null && isBeforeNow(new Date(expireAt)))
				localStorage.removeItem(key);
		},
	},

	/**
	 * Set a value in the local storage
	 * @param {string} key - The name of the value
	 * @param {any} value - The value to set
	 * @param {Date|null} expireAt - The expiration date
	 * @returns LocalStorageHelper
	 */
	set(key, value, { expireAt = null }){
		localStorage.setItem(key, JSON.stringify({
			expireAt: expireAt.valueOf(),
			value,
		}));

		return this;
	},
	/**
	 * Get the value back from local storage
	 * @param {string} key - The name of the value
	 * @param {any} [defaultValue = null] - The value to return if the value is not in storage
	 * @returns {any|null}
	 */
	get(key, defaultValue = null){
		this.__.removeIfExpired(key);
		const payload = this.__.getFromLocalStorage(key);
		if(!isValidPayload(payload))
			return defaultValue;

		const { value } = payload;

		return value;
	},
	/**
	 * Determine whether or not the value is in local storage
	 * @param {string} key - The name of the value
	 * @returns {boolean}
	 */
	has(key){
		return this.get(key, null) !== null;
	},
};
