import Feedizi from '../libs/Feedizi'
import Join from '../libs/Join'

export default class PageRenderer {
	/**
	 * @param {BrowserDetector} browserDetector
	 */
    onEnter(browserDetector) {
        const realisation = document.querySelector('.m-view-realisations')
        if(realisation) {
            new Feedizi(realisation, {
                rowClass: 'm-view-realisations__realisation'
            })
            //Put tags in a container, and just before realisation section
            const tagsContainer = document.createElement('div')

            tagsContainer.classList.add('m-view-realisations__tagsContainer')
            const tagsRealisation = document.querySelector('.m-view-realisations__tags')

            if(tagsRealisation) {
                tagsContainer.appendChild(tagsRealisation)
                document.querySelector('.m-view-realisations').parentNode.prepend(tagsContainer)
            }
        }
        const trend = document.querySelector('.m-view-trends')
        if(trend) {
            new Feedizi(trend, {
                rowClass: 'm-view-trends__trend'
            })
            //Put tags in a container, and just before trend section
            const tagsContainer = document.createElement('div')

            tagsContainer.classList.add('m-view-trends__tagsContainer')
            const tagsTrend = document.querySelector('.m-view-trends__tags')

            if(tagsTrend) {
                tagsContainer.appendChild(tagsTrend)
                document.querySelector('.m-view-trends').parentNode.prepend(tagsContainer)
            }
        }

        // Join project
        const join = document.querySelector('.m-join')
        if(join) {
            new Join(join, browserDetector)
        }
    }
}
