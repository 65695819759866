export default class Scrollino {

    constructor() {
        // Init
        this.currentScrollTop = 0
        this.prevScrollTop = 0
        this.currentDir = false

        //Listen Scroll
        this.listenScroll()

        window.addEventListener('scroll', () => {
            this.listenScroll()
        }, false)
    }

    listenScroll(){
        this.currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        // On down scroll
        if (!this.currentDir && this.currentScrollTop > this.prevScrollTop) {
            this.prevScrollTop = this.currentScrollTop
           document.body.setAttribute('data-scroll', 'down')
           this.currentDir = true
        // On up scroll
        } else if (this.currentDir && this.currentScrollTop < this.prevScrollTop) {
            this.prevScrollTop = this.currentScrollTop
           document.body.setAttribute('data-scroll', 'up')
           this.currentDir = false
        }
        // Has scrolled ?
        if(this.currentScrollTop > 26) {
            document.body.classList.add('o-body--scrolled')
        } else {
            document.body.classList.remove('o-body--scrolled')
        }
        this.prevScrollTop = this.currentScrollTop
    }
}