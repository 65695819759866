import "@scss/main.scss";
import "picturefill";
import "lazysizes";
import { makeBrowserDetector } from "./libs/BrowserDetector";
import Scrollino from "./libs/Scrollino";
import {
	aboveBreakpoint,
	belowBreakpoint,
	Breakpoints,
	currentBreakpoint,
} from "@js/modules/breakpoints";
import { ls } from "@js/modules/localStorage";
import { tomorrow } from "@js/modules/date";
import { Utm } from "./libs/Utm";
import { lockBody, unlockBody, toggleLockBody } from "@js/modules/body";
import { slideUp, slideDown } from "es6-slide-up-down";
import { easeInOutCubic } from "es6-easings";
import HomeRenderer from "./renderers/HomeRenderer";
import PageRenderer from "./renderers/PageRenderer";

// register CSS classes on <html> to help with browser detection in CSS
const browserDetector = makeBrowserDetector(window).bootstrapClasses();

const rendererFactory = type => {
	switch (type) {
		case "home":
			return new HomeRenderer();
		default:
			return new PageRenderer();
	}
};

const handleRenderer = () => {
	// Renderer
	const renderer = rendererFactory(document.querySelector(".o-body").dataset.type);
	renderer.onEnter(browserDetector);
};


const handleBurgerMenu = () => {
	// Burger
	const burger = document.querySelector(".a-burger");
	const background = document.querySelector(".m-menu__background");
	const submenus = document.querySelectorAll(".m-nav-main__item--expanded");
	const breakpoint = Breakpoints.xlarge;
	const openedClass = "o-body--menu-on";


	if (burger && background) {
		burger.addEventListener("click", () => {
			if (belowBreakpoint(breakpoint)) // only toggle on correct breakpoints
			{
				document.body.classList.toggle(openedClass);
			}
			toggleLockBody();
		});

		background.addEventListener("click", () => {
			if (belowBreakpoint(breakpoint)) // only remove on correct breakpoints
			{
				document.body.classList.remove(openedClass);
			}
			unlockBody();
		});
		if (submenus) {
			submenus.forEach(submenu => {
				submenu.addEventListener("click", e => {
					if(e.target.matches("a[href]"))
						return;

					if (belowBreakpoint(breakpoint)) {
						e.preventDefault();
						if (submenu.querySelector(".m-nav-main__sub").style.display == "block") {
							submenu.querySelector(".m-nav-main__link").classList.remove("m-nav-main__link--active");
							slideUp(submenu.querySelector(".m-nav-main__sub"), {
								duration: 600,
								easing: easeInOutCubic,
							});
						} else {
							submenu.querySelector(".m-nav-main__link").classList.add("m-nav-main__link--active");
							slideDown(submenu.querySelector(".m-nav-main__sub"), {
								duration: 600,
								easing: easeInOutCubic,
							});
						}
					}
				});
			});
		}

		let lastBreakpoint = currentBreakpoint();
		const onResize = () => {
			const bp = currentBreakpoint();

			if (aboveBreakpoint(lastBreakpoint) && aboveBreakpoint(breakpoint) && document.body.classList.contains(openedClass)) {
				document.body.classList.remove(openedClass);
			}
			unlockBody();

			lastBreakpoint = bp;
		};

		window.addEventListener("resize", onResize);
		window.addEventListener("orientationchange", onResize);
		onResize();
	}

	return {
		burger,
		background,
	};
};


function hideContentInfoOnClick() {

	const contentinfo = document.querySelector(".contentinfo");

	if (contentinfo) {
		contentinfo.addEventListener("click", () => {
			contentinfo.classList.add("contentinfo--hidden");
		});
	}
}

const handleTopNews = () => {
	const LOCAL_STORAGE_KEY = "topNews";
	const close_new = document.querySelector(".o-top-news__close");
	const top_news = document.querySelector(".o-top-news");
	const top_news_header = document.querySelector(".o-header");
	const top_news_breadcrumb = document.querySelector(".a-breadcrumb");

	const hideIt = () => {
		if (top_news) {
			top_news.classList.add("o-top-news--hide");
		}

		if (top_news_header) {
			top_news_header.classList.remove("o-header--top-news");
		}

		if (top_news_breadcrumb) {
			top_news_breadcrumb.classList.remove("a-breadcrumb--top-news");
		}
	};

	if (top_news) {
		if (ls.has(LOCAL_STORAGE_KEY)) {
			hideIt();
			return;
		} else {
			top_news.classList.remove("o-top-news__hide");
		}

		close_new.addEventListener("click", (e) => {
			e.preventDefault();

			hideIt();

			ls.set(LOCAL_STORAGE_KEY, true, {
				expireAt: tomorrow(),
			});
		}, false);
	}

	return {
		close_new,
		top_news,
		top_news_header,
		top_news_breadcrumb,
	};
};


document.addEventListener("DOMContentLoaded", function () {
	// UTM to Cookies
	const utm = new Utm();
	utm.makeCookies();
	handleBurgerMenu();
	handleRenderer();
	hideContentInfoOnClick();
	handleTopNews();

	// Scrollino
	new Scrollino();


	const send = XMLHttpRequest.prototype.send;
	XMLHttpRequest.prototype.send = function () {
			this.addEventListener("load", function () {
				hideContentInfoOnClick();
			});
			return send.apply(this, arguments);
		};
});

// SWPL LOG
console.log(
	"%c S W P L ",
	`background: #1467ff;
	color: white;
	font-size: 17px;
	font-weight: bold;
	line-height: 36px; text-align: center`,
	" www.swpl.fr"
)
