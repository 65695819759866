const bodyLockClass = "o-body--locked";

export const lockBody = () => {
	document.body.classList.add(bodyLockClass);
};

export const unlockBody = () => {
	document.body.classList.remove(bodyLockClass);
};

export const toggleLockBody = () => {
	document.body.classList.toggle(bodyLockClass);
};

