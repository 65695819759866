import Swiper from 'swiper/bundle'
export default class HomeRenderer {
    onEnter() {
        const reviewsCustomer = () => {
            // item sub menu	
            const reviews = document.querySelector('.m-cards-reviews')
            if (reviews) {
                new Swiper(reviews.querySelector('.swiper-container'), {    
                    slidesPerView: 'auto',
                    spaceBetween: 20,
                    freeMode: {
                        enabled: true,
                    },
                    grabCursor: true,
                    breakpoints: {
                        640: {
                            spaceBetween: 40,
                        },
                    },
                })
            }
                    
            return {
                reviews,		
            }	
        }
        //Call function
        reviewsCustomer();
    }
}