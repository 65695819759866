export const time = {
	/**
	 * Get the duration (noop)
	 * @param {number} n
	 * @returns {number}
	 */
	milliseconds(n){
		return n;
	},
	/**
	 * Get the duration (in milliseconds)
	 * @param {number} n
	 * @returns {number}
	 */
	seconds(n) {
		return this.milliseconds(1000 * n);
	},
	/**
	 * Get the duration (in milliseconds)
	 * @param {number} n
	 * @returns {number}
	 */
	minutes(n) {
		return this.seconds(60 * n);
	},
	/**
	 * Get the duration (in milliseconds)
	 * @param {number} n
	 * @returns {number}
	 */
	hours(n) {
		return this.minutes(60 * n);
	},
	/**
	 * Get the duration (in milliseconds)
	 * @param {number} n
	 * @returns {number}
	 */
	days(n) {
		return this.hours(24 * n);
	},
	/**
	 * Get the duration (in milliseconds)
	 * @param {number} n
	 * @returns {number}
	 */
	weeks(n) {
		return this.days(7 * n);
	},
};
