export const Breakpoints = {
	__default: 0,
	small: 568,
	medium: 768,
	large: 1024,
	xlarge: 1280,
	xxlarge: 1440,
	xxxlarge: 1680,
};

export const belowBreakpoint = bp => window.innerWidth < bp;

export const aboveBreakpoint = bp => !belowBreakpoint(bp);

export const currentBreakpoint = () => {
	/*
		 Current breakpoint is the last for which the
		 window's inner width is above
	 */
	return Object.values(Breakpoints)
		.reverse()
		.find(aboveBreakpoint);
};

export const currentBreakpointIs = bp => {
	const current = currentBreakpoint();
	return bp === current;
};
