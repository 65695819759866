import Swiper from 'swiper/bundle'
import SmoothScroll from 'smooth-scroll'

export default class Join {
	/**
	 * @param {HTMLElement|string} el
	 * @param {BrowserDetector} browserDetector
	 * @returns {boolean}
	 */
    constructor(el, browserDetector) {
    	this.browserDetector = browserDetector;

        // Check element
        if(el instanceof HTMLElement) {
            this.el = el
        } else {
            this.el = document.querySelector(el)
        }
        if(!this.el) {
            console.warn('No valid div found. Sorry bro.')
            return false
        }
        this.scroll = new SmoothScroll()
        this.scrollEvent = false

        this.disabledSecondSlide = false

        // Get DOM
        this.form = this.el.querySelector('form')
        this.action = this.form.action
        this.wrapper = this.el.querySelector('.webform-elements')
        this.tabs = this.el.querySelector('.m-join__tabs')
        this.next = this.el.querySelector('.m-join__next')
        this.submit = this.el.querySelector('.m-join__submit')

        const descriptions = el.querySelectorAll('.js-form-type-radio > .description')

        if(descriptions) {
            descriptions.forEach(description => {
                const contentLabel = description.previousElementSibling
                contentLabel.classList.add('m-join__picto')
                contentLabel.classList.add('m-join__picto--' + description.textContent.trim())
                document.styleSheets[0].addRule('.m-join__picto--'+description.textContent.trim()+'::before','content: ""; background-image: url(../img/'+description.textContent.trim()+'.svg);');

            });
        }

        const labels = el.querySelectorAll('label')

        if(labels) {
            labels.forEach(label => {
                const first = label.textContent.indexOf('(')
                const second = label.textContent.indexOf(')')

                if(first >= 0 && second >= 0) {
                    const spanContent = label.textContent.substring(first, second +1)
                    label.innerHTML = label.textContent.replace(spanContent, '<span>'+spanContent+'</span>')

                }
            });
        }


        // Add swiper classes
        this.form.classList.add('swiper-container')
        // If no wrapper, create one
        if(!this.wrapper) {
            // If there's no wrapper, wrap slides
            this.wrapper = document.createElement('div')
            this.wrapper.classList.add('swiper-wrapper')
            this.wrapper.innerHTML = this.form.innerHTML
            this.form.innerHTML = ''
            this.form.append(this.wrapper)
        } else {
            this.wrapper.classList.add('swiper-wrapper')
        }
        // Get slides
        this.slides = this.form.querySelectorAll('.swiper-wrapper > fieldset')
        this.slides.forEach(slide => {
            slide.classList.add('swiper-slide')
        })

        // Init swiper
        this.slider = new Swiper(this.form, {
            // effect: 'fade',
            autoHeight: true,
            // fadeEffect: {
            //     crossFade: true
            // },
            speed: 0,
            threshold: 30,
            init: false,
            allowTouchMove: false,
            touchRatio: 1,
            on: {
                init: this.init.bind(this),
                slideChange: this.slideChange.bind(this)
            }
        })
        this.slider.init()

        // Listen buttons
        this.listenButtons()

        // First check on input
        this.checkInputs()

        // Listen inputs change to check them
        this.listenInputs()
    }
    checkInputs() {
        // TODO : see why date input doesnt get this event at start
        this.inputs = this.form.querySelectorAll('input')
        this.inputs.forEach(input => {
            this.showValidity(input)
        })
        this.selects = this.form.querySelectorAll('select')
        this.selects.forEach(select => {
            this.showValidity(select)
        })
        this.textareas = this.form.querySelectorAll('textarea')
        this.textareas.forEach(textarea => {
            this.showValidity(textarea)
        })
    }
    listenInputs() {
        // TODO : see why date input doesnt get this event at start
        this.inputs.forEach(input => {
            input.addEventListener('input', () => {
                let shouldMove = this.showValidity(input) && input.type === "radio";
                // Zipcode logic
                if(input.name === 'zipcode')
                    this.zipcodeInput(input, this.form.querySelector('input[name="city"]'))
                if(input.name === 'organization_zipcode')
                    this.zipcodeInput(input, this.form.querySelector('input[name="organization_town"]'))
                // Clean Email custom validity
                if(input.type === 'email')
                    input.setCustomValidity('')

                // Different slide if it's not renovation
                if(input.name == 'subject') {
                    if(input.value == 'Agrandissement') {
                        this.slider.slideTo(2)
                        shouldMove = false
                        this.tabs.querySelector('[data-value="1"]').classList.add('m-join__tab--disabled')
                        this.disabledSecondSlide = true
                    } else {
                        this.tabs.querySelector('[data-value="1"]').classList.remove('m-join__tab--disabled')
                        this.disabledSecondSlide = false
                    }
                }

                // Radio button trigger automatic slide change
				if(shouldMove)
					this.next.click();
            })
            // Add Email custom validity for IE11 on blur
            if(input.type === 'email') {
                // Custom validity on blur
                input.addEventListener('blur', () => {
                    if(!input.checkValidity())
                        input.setCustomValidity('Vous devez entrer une adresse e-mail valide.')
                })
            }
        })
        this.selects.forEach(select => {
            select.addEventListener('input', () => {
                this.showValidity(select)
            })
        })
        this.textareas.forEach(textarea => {
            textarea.addEventListener('input', () => {
                this.showValidity(textarea)
            })
        })
    }
    zipcodeInput(input, city) {
        // Return false if zipcode is not valid
        if(!input.checkValidity() || input.value === '') {
            return false
        }
        // Set loading status on city
        city.parentNode.classList.add('loading')
        // Send API request to get city name
        const request = new XMLHttpRequest();
        request.open("GET", "https://vicopo.selfbuild.fr/cherche/" + input.value )
        request.send()
        const _this = this
        request.onload = function() {
            const obj = JSON.parse(request.response)
            if(obj.cities['length'] != 0) {
                city.value = obj.cities[0].city
                _this.showValidity(city)
            }
            city.parentNode.classList.remove('loading')
        }
    }

	/**
	 * @param {HTMLInputElement} input
	 * @returns {boolean} Whether or not it was valid
	 */
	showValidity(input) {
        // Check input validity
        if(input.checkValidity()) {
            input.classList.remove('warning')
            if(input.value !== '' ) {
                input.classList.add('valid')
				return true;
            } else {
                input.classList.remove('valid')
            }
        } else {
            input.classList.remove('valid')
            if(input.value !== '' )
                input.classList.add('warning')
        }

        return false;
    }
    slideChange(swiper) {
        this.tabs.querySelector('.m-join__tab--active').classList.remove('m-join__tab--active')
        this.tabs.querySelector(`.m-join__tab[data-value="${swiper.realIndex}"]`).classList.add('m-join__tab--active')


        // // Force slide height : IE fix
        if(this.browserDetector.ie()) {
            swiper.wrapperEl.style.height = `${swiper.slides[swiper.realIndex].querySelector('.fieldset-wrapper').offsetHeight + 20}px`
        }
    }
    init(swiper) {
        let count = 0
        swiper.slides.forEach(slide => {
            // Create tab
            const tab = document.createElement('a')
            tab.setAttribute('href', '#')
            tab.dataset.value = count
            tab.classList.add('m-join__tab')
            // Add active class on first tab
            if(count == 0)
                tab.classList.add('m-join__tab--active')
            tab.classList.add('m-join__tab')
            // Append tab number
            const number = document.createElement('span')
            number.classList.add('m-join__tab-number')
            number.innerHTML = count + 1
            tab.append(number)
            // Append tab title
            const title = document.createElement('span')
            title.classList.add('m-join__tab-title')
            title.innerHTML = slide.querySelector('.fieldset-legend').innerHTML
            tab.append(title)
            this.tabs.append(tab)
            // Listen tab
            tab.addEventListener('click', e => {
                e.preventDefault()
                this.tabClick(tab)
            })
            // Increment count
            count++
        })
    }
    tabClick(tab) {
        // Check current fieldset if user wanna access a next tab
        let check = true
        // console.log(tab.dataset.value, this.slider.realIndex)
        if(tab.dataset.value > this.slider.realIndex) {
            check = this.checkFieldset()
        } else {
            check = true
        }
        if(!check)
            return false
        // Check previous fieldsets
        let previousFieldsetsValidity = true
        let lastValidFieldset = this.slider.realIndex
        // Iterate over previous fieldset
        for (let i = tab.dataset.value - 1; i >= 0; i--) {
            // Check fieldset validity
            const validity = this.checkFieldset(i)
            if(i > this.slider.realIndex && i !== this.slider.realIndex && previousFieldsetsValidity) {
                previousFieldsetsValidity = validity
            }
            if(validity && i > lastValidFieldset)
                lastValidFieldset = i
        }
        if(previousFieldsetsValidity) {
            // Everything is valide lets go
            this.goToSlide(tab.dataset.value)
        } else {
            // Go to max valid slide
            this.goToSlide(lastValidFieldset)
        }
    }


    _goForward(){
		// Check fieldset
		const check = this.checkFieldset()
		if(check) {
            if(this.slider.realIndex == 0 && this.disabledSecondSlide) {
                this.goToSlide(this.slider.realIndex + 2)
            } else {
                this.goToSlide(this.slider.realIndex + 1)
            }
        }
	}

	_submit(){
		// Check fieldset
		const check = this.checkFieldset()
		if(check) {
			// Next slide
			this.form.submit()
		}
	}


    listenButtons() {
        // Next button
        this.next.addEventListener('click', e => {
            e.preventDefault()
			this._goForward();
        })

        // Submit button
        this.submit.addEventListener('click', e => {
            e.preventDefault()
			this._submit();
        })
    }
    goToSlide(index) {
        // Animate including header height
        const elementTop = Math.round(this.getTop(this.el) - document.querySelector('header').offsetHeight - 40)
        // Use smoothscroll only if we re far from slider top
        if(elementTop <= document.documentElement.scrollTop - 5 || elementTop >= document.documentElement.scrollTop + 5)
            this.scroll.animateScroll(elementTop)
        // Next slide
        this.slider.slideTo(index)
        // Remove next button, show submit button on last
        if(this.slider.slides.length === this.slider.realIndex + 1) {
            this.next.classList.add('a-btn--hidden')
            this.submit.classList.remove('a-btn--hidden')
        } else if(this.next.classList.contains('a-btn--hidden')) {
            this.next.classList.remove('a-btn--hidden')
            this.submit.classList.add('a-btn--hidden')
        }
    }
    checkFieldset(fieldsetIndex = false) {
        const fieldset = this.slider.slides[fieldsetIndex ? fieldsetIndex : this.slider.realIndex]
        let valid = true
        let first = true
        // Check if one required item isnt valid in fieldset
        fieldset.querySelectorAll('.form-item').forEach(item => {
            // Input check
            if(item.querySelector('input') && !item.querySelector('input').checkValidity())
                valid = false
            // Textarea check
            if(item.querySelector('textarea') && !item.querySelector('textarea').checkValidity())
                valid = false
            // Select check
            if(item.querySelector('select') && !item.querySelector('select').checkValidity())
                valid = false
            // If its first not valid, scroll to input
            if(valid == false && first) {
                const itemTop = Math.round(this.getTop(item) - document.querySelector('header').offsetHeight - 40)
                // Use smoothscroll only if we re far from input
                if(itemTop <= document.documentElement.scrollTop - 5 || itemTop >= document.documentElement.scrollTop + 5)
                    this.scroll.animateScroll(itemTop)
                first = false
            }
        })
        // Trigger automatic form validation by navigator
        if(!valid)
            this.form.reportValidity()
        // Return valid state
        return valid
    }
    getTop(item) {
        const bounds = item.getBoundingClientRect()
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        return bounds.top + scrollTop
    }
}
